/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import { bool, number, shape, string } from 'prop-types';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { urls as solitaireUrls } from 'invaluable-solitaire';
import { openOnNewWindow, fetchConsoleLink } from 'inv-common-components';

import { useMedia } from 'src/hooks';
import { MOBILE_MEDIA_QUERY } from 'src/components/common/constants';
import { getImageBaseDomain, handleClickRFA } from 'src/utils/common/helpers';

import { getAuctionHouseURL } from 'src/utils/pdp/helpers';
import { getAuctionLotUrl, replaceThzFromUrl } from 'src/utils/featuredLots/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectSessionInfo } from 'src/selectors/inv-common';
import useTimeZoneLocal from '../../hooks/useTimezoneLocal';
import { getImageEnv } from '../../../config';
import SellerReviews from '../pdp/seller/SellerReviews';
import { FeaturedListWrapper } from '../featuredListWrapper/FeaturedListWrapper';

function UpcomingAuction({ auction, isProduction, xAuthToken, }) {
  const {
    catalogTitle,
    displayParityBanner,
    eventLocalDate,
    eventDate,
    inProgress,
    isLive,
    isTimed,
    location: {
      location,
    },
    ref,
    sellerModel: {
      sellerName,
      ref: sellerRef,
    },
    timeZone,
  } = auction;

  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(selectSessionInfo);
  const auctionDateTime = `${moment.utc(eventLocalDate).locale('en-us').format('LLL')} ${timeZone}`;
  const linkCatalogURLRelative = `/catalog/${solitaireUrls.seoFriendlyUrl(ref)}`;
  const linkRFAURL = `/bidNow/reqForApproval?catalogRef=${ref}`;
  const houseURL = `/auction-house/${solitaireUrls.seoFriendlyUrl(sellerName)}-${solitaireUrls.seoFriendlyUrl(sellerRef)}`;
  const approvalStatus = get(auction, 'bidderCatalogStatus.approvalStat', null);
  const consoleOpen = isLive && inProgress;
  const { convertedDate, userTimeZone } = useTimeZoneLocal(eventDate, false, inProgress);

  const sellerProfileLink = getAuctionHouseURL(sellerName, sellerRef);
  const noImageURL = `${getImageBaseDomain(isProduction)}/static/home/noimage2x_250x250.png`;

  const isMobile = useMedia(MOBILE_MEDIA_QUERY);
  const hasFeaturedLots = auction.hasFeaturedLots ?? auction.featuredLots?.length > 0;

  const catalogImageExists = get(auction, 'links', []).findIndex(e => e.rel === 'catalogFullImage');
  const coverImageURL = catalogImageExists > -1 ? auction.links[catalogImageExists].href : `https://${getImageEnv()}.invaluable.com/static/noimage_noborder.png`;

  const handleRegisterToBidClick = () => {
    dispatch(handleClickRFA({ isLoggedIn, linkRFAURL }));
  };

  const liveConsoleLink = async () => {
    const channelID = 1;

    try {
      const consoleUrlResponse = await fetchConsoleLink({ catalogRef: ref, xAuthToken, channelID });
      openOnNewWindow(consoleUrlResponse.data.consoleUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error setting up console', error);
    }
  };

  const renderEnterAuctionButton = () => {
    // Live Auction in Progress
    if (consoleOpen) {
      return (
        <a
          role="button"
          className="btn btn-live full-width"
          onClick={() => liveConsoleLink()}
        >Enter Live Auction
        </a>
      );
    }

    // Timed Auctions should display in red
    if (isTimed && inProgress) {
      return <a href={linkCatalogURLRelative} className="btn btn-live full-width">Happening Now</a>;
    }

    return <a href={linkCatalogURLRelative} className="btn btn-primary full-width">View Items</a>;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function renderFeaturedLotsSection() {
    return (
      <FeaturedListWrapper isMobileView={isMobile}>
        {auction.featuredLots?.map(
          ({ imgUrl, lotRef, lotNumber, lotTitle = '' }) => (
            <div
              key={lotRef}
              className="featured-container"
            >
              <div className="feature-badge">
                <span>featured</span>
              </div>
              <a
                href={getAuctionLotUrl(lotTitle, lotNumber, lotRef)}
                aria-label={`Go to ${catalogTitle}`}
                target="_blank"
                rel="noreferrer"
                className="img-anchor"
              >
                <img
                  className="img-featured"
                  src={imgUrl ? replaceThzFromUrl(imgUrl) : noImageURL}
                  alt=""
                />
              </a>
            </div>
          )

        )}
      </FeaturedListWrapper>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function renderAuctionButtonsSection() {
    return (
      isMobile ? (
        <>
          <div className="d-flex">
            <div className="col-6 col-sm-6">
              <p>
                {isLive ? (
                  <i className="fa fa-feed" />
                ) : (
                  <i className="fa fa-clock-o" />
                )}
                  &nbsp;
                {isLive ? 'Live' : 'Timed'} Auction
              </p>
            </div>
            <div className="col-6 col-sm-6">
              <p className="pull-right">
                {approvalStatus === 'a' && (
                <>
                  <i className="fa fa-check text-success" /> Approved to bid
                </>
                )}
                {approvalStatus === 'p' && (
                <>
                  <i className="fa fa-refresh text-warning" /> Pending
                  approval
                </>
                )}
                {!approvalStatus && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  role="button"
                  className="registerToBidLogin link"
                  tabIndex="0"
                  data-rfa-link={linkRFAURL}
                  onClick={handleRegisterToBidClick}
                  rel="nofollow"
                >
                  Register to bid
                </a>
                )}
              </p>
            </div>

          </div>
          <div className="col-12 col-sm-12">
            <p>{renderEnterAuctionButton()}</p>
          </div>
        </>
      ) : (
        <div>
          <p>
            {isLive ? (
              <i className="fa fa-feed" />
            ) : (
              <i className="fa fa-clock-o" />
            )}
            {isLive ? 'Live' : 'Timed'} Auction
          </p>
          <p>{renderEnterAuctionButton()}</p>
          <p className="flush-right-left-text">
            {approvalStatus === 'a' && (
              <>
                <i className="fa fa-check text-success" /> Approved to bid
              </>
            )}
            {approvalStatus === 'p' && (
              <>
                <i className="fa fa-refresh text-warning" /> Pending
                approval
              </>
            )}
            {!approvalStatus && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                role="button"
                className="registerToBidLogin link"
                tabIndex="0"
                data-rfa-link={linkRFAURL}
                onClick={handleRegisterToBidClick}
                rel="nofollow"
              >
                Register to bid
              </a>
            )}
          </p>
        </div>
      )

    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function renderAuctionDetailSection() {
    return (
      <>
        <h3 className="space-top-none">
          <a href={linkCatalogURLRelative}>{catalogTitle}</a>
        </h3>
        <p className="space-top-sm">
          <a href={houseURL} className="link-color-black">
            by {sellerName}
          </a>
        </p>

        {hasFeaturedLots && (
          <div>
            <SellerReviews
              showRatingText
              sellerProfileLink={sellerProfileLink}
              sellerRef={sellerRef}
              minRatingThreshold={4}
              itemKey={ref}
            />
          </div>
        )}

        <p className="space-bottom-none">
          <span className="localized-date hide d-none">
            {convertedDate || auctionDateTime}{' '}
            {userTimeZone && userTimeZone}
          </span>
          <span className="non-localized-date">{auctionDateTime}</span>
        </p>
        <p>{location}</p>
      </>
    );
  }

  return (
    <div
      className={`${
        displayParityBanner
          ? 'bp-parity-border-auctions'
          : 'bordered-bottom padding-bottom-wide'
      }`}
    >
      <div className="row space-top-wide">
        <div className="col-3 col-sm-3 col-md-3">
          <div className="thumb-container">
            <div className="thumb">
              <a
                href={linkCatalogURLRelative}
                aria-label={`Go to ${catalogTitle}`}
              >
                {/**
                 * (dyouberg) TODO: this doesn't work yet, lazysizes approach is in the OAS repo.
                 *
                 * Waiting on discussions with Steve Patton regarding SEO impacts before
                 * doing the <LazyLoad> approach throughout this repo.
                 */}
                <img
                  src={coverImageURL}
                  alt={catalogTitle}
                  className="lazyloaded"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-9 col-sm-9 col-md-9">
          <div className="row">
            <div
              className={`col-md-${displayParityBanner ? 7 : 8} col-sm-${displayParityBanner ? 9 : 12} col-${displayParityBanner ? 9 : 12}`}
            >
              {renderAuctionDetailSection()}
            </div>

            {displayParityBanner && (
              <div className="col-3 col-sm-3 col-md-1">
                <img
                  className="bp-parity-badge-auctions"
                  src={`https://${getImageEnv()}.invaluable.com/static/home/BP_Badge.png`}
                  alt="bp parity badge"
                />
              </div>
            )}
            {!isMobile && (
              <div className="col-4 col-sm-4 col-md-4">
                {renderAuctionButtonsSection()}
              </div>
            )}
          </div>

          {!isMobile && (
          <div className="col-12 col-md-12">
            {renderFeaturedLotsSection()}
          </div>
          )}

        </div>

        {isMobile && (
          <>
            <div className="col-12 col-sm-12 col-md-12">
              {renderFeaturedLotsSection()}
            </div>
            <div className="col-12 col-sm-12 col-md-4">
              {renderAuctionButtonsSection()}
            </div>
          </>

        )}

      </div>
    </div>
  );
}

UpcomingAuction.propTypes = {
  auction: shape({
    catalogTitle: string,
    displayParityBanner: bool,
    eventLocalDate: number,
    isLive: bool,
    location: shape({
      location: string,
    }),
    ref: string,
    sellerModel: shape({
      sellerName: string,
    }),
    timeZone: string,
  }).isRequired,
  isProduction: bool
};

UpcomingAuction.defaultProps = {
  isProduction: false
};

export default UpcomingAuction;
