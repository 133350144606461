/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { string, shape } from 'prop-types';
import { openOnNewWindow, fetchConsoleLink } from 'inv-common-components';
import { getImageEnv } from '../../../config';
import useTimeZoneLocal from '../../hooks/useTimezoneLocal';

function MyAuctionsCard({ auction, xAuthToken }) {
  const [watcherCount, setWatcherCount] = useState(0);
  const currentTime = new Date().getTime();
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'UTC' };
  const auctionDate = `${new Date(auction.catalogDate).toLocaleDateString('en-US', options)} ${auction.catalogDateTimeZone}`;
  const catalogRef = auction.ref.toUpperCase();
  const isTimed = auction.catalogType === 'TIMED';
  const isLive = auction.catalogType === 'LIVE';
  const inProgress = auction.catalogStatus === 'ACTIVE';
  const sellerName = auction.houseName;
  const sellerNameURL = sellerName.replace(/\s+/g, '-').replace(/\./g, '');

  const { convertedDate, userTimeZone } = useTimeZoneLocal(auction.catalogDateUTC, false, inProgress);

  const sellerRef = auction.houseRef;
  const isUpcoming = auction.catalogStatus === 'UPCOMING';

  function isValidImage(url) {
    return (url.match(/\.(jpeg|JPEG|jpg|JPG|gif|GIF|png|PNG|)$/) != null);
  }

  const getWatcherCount = async () => {
    try {
      const watcherCountRes = await axios.get(`/api/auctions/catalog/watcherCount?catalogRefs=${catalogRef}`);
      setWatcherCount(watcherCountRes.data[catalogRef]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`watcher count call error [catalogRef=${catalogRef}]`, error);
      return null;
    }
  };

  useEffect(() => {
    if (isLive && inProgress) {
      getWatcherCount();
    }
  }, []);

  const liveConsoleLink = async () => {
    const channelID = 1;

    try {
      const consoleUrlResponse = await fetchConsoleLink({ catalogRef, xAuthToken, channelID });
      openOnNewWindow(consoleUrlResponse.data.consoleUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error setting up console', error);
    }
  };

  return (
    <div
      className="col-12 col-sm-12"
    >
      <div className="myauctions-container">
        {(isUpcoming && isLive) && <div className="my-auctions-badge"><span className="badge badge-dark pl-2 pr-2 bid-pill">&nbsp;accepting bids&nbsp;</span></div>}
        {(isUpcoming && isTimed && (auction.catalogDateUTC - currentTime > 86400000)) && <div className="my-auctions-badge"><span className="badge badge-dark pl-2 pr-2 bid-pill">&nbsp;accepting bids&nbsp;</span></div>}
        {(isTimed && isUpcoming && !inProgress && (auction.catalogDateUTC - currentTime < 86400000)) && <div className="my-auctions-badge"><span className="badge badge-danger pl-2 pr-2 bid-pill">&nbsp;closing soon&nbsp;</span></div>}
        {(isTimed && inProgress && (auction.catalogDateUTC - currentTime < 86400000)) && <div className="my-auctions-badge"><span className="badge badge-danger pl-2 pr-2 bid-pill">&nbsp;closing now&nbsp;</span></div>}
        {!isUpcoming && !inProgress && <div className="my-auctions-badge"><span className="badge badge-dark bid-pill">&nbsp;auction closed&nbsp;</span></div> }
        {(isLive && inProgress && watcherCount < 30) && <div className="my-auctions-badge"><span className="badge badge-danger bid-pill capitalize">&nbsp;LIVE&nbsp;</span></div> }
        {(isLive && inProgress && watcherCount >= 30) && <div className="my-auctions-badge"><span className="badge badge-danger bid-pill"><i className="fa fa-eye" />&nbsp;{watcherCount} watchers&nbsp;</span></div> }

        {auction.userStatus === 'APPROVED' && <div className="my-auctions-badge-second"><span className="badge badge-success">&nbsp;Approved&nbsp;</span></div>}
        {auction.userStatus === 'PENDING' && <div className="my-auctions-badge-second"><span className="badge badge-warning">&nbsp;Pending&nbsp;</span></div>}
        <div className="my-auctions-thumb-container no-border">
          <a
            href={`/catalog/${catalogRef}`}
          >
            <img
              className="my-auctions-img"
              src={isValidImage(auction.coverImage) ? auction.coverImage : `https://${getImageEnv()}.invaluable.com/static/noimage_noborder.png`}
              alt="242x200"
            />
          </a>
        </div>
        <div className="my-auctions-time space-top">
          <i className={`fa ${isTimed ? 'fa-clock-o' : 'fa-rss'} ${inProgress ? 'text-live' : ''}`} />
          <span className={inProgress ? 'text-live' : ''}>&nbsp;
            <span>{convertedDate || auctionDate} {userTimeZone && userTimeZone}</span>
          </span>
        </div>
        <h3 className="my-auctions-space">
          <a
            href={`/catalog/${catalogRef}`}
          > {auction.catalogTitle}
          </a>
        </h3>
        <div className="my-auctions-house">
          by <a
            href={`/auction-house/${sellerNameURL}-${sellerRef}`}
          >{sellerName}
             </a>
        </div>
        {(isUpcoming || isTimed)
            && (
            <a
              href={`/catalog/${catalogRef}`}
              className="btn btn-primary full-width"
            >View Items
            </a>
            )}
        { (inProgress && isLive)
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/interactive-supports-focus,  jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid
            && (
            <a
              className="btn btn-live full-width"
              role="button"
              onClick={() => liveConsoleLink()}
            >Enter Live Auction
            </a>
            )}
      </div>
    </div>
  );
}

MyAuctionsCard.propTypes = {
  auction: shape({
    catalogDateTimeZone: string.isRequired,
    userStatus: string.isRequired,
    catalogStatus: string.isRequired,
    houseName: string.isRequired,
    houseRef: string.isRequired,
  }).isRequired,
};

export default MyAuctionsCard;
